<template>
  <div class="single-platform-engagement-entry">
    <div class="header">
      <div :class="['icon-wrapper', platform]">
        <svg-icon class="icon" :name="`${platform}-colored`"></svg-icon>
      </div>
      <div class="name">
        {{ platform }}
      </div>
    </div>
    <div class="data">
      <div class="chart-wrapper">
        <canvas ref="chart"></canvas>
        <span class="value">{{ totalValue }}</span>
      </div>
      <div class="caption">
        {{
          postsCount
            ? `Total Engagement from ${postsCount} post${postsCount > 1 ? 's' : ''} sent`
            : 'Total Engagement Data'
        }}
      </div>

      <div class="value-wrapper">
        <template v-if="totalValue">
          <div v-for="(keyStr, index) in keys" :class="[keyStr]" :key="keyStr">
            <div class="title">{{ keyStr }}</div>
            <div class="value">{{ values[index] }}</div>
          </div>
        </template>
        <div clas="empty" v-else key="empty">
          <div class="title">No data found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartJs from 'chart.js';
import { get, fill, reduce, isEqual } from 'lodash';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chartInstance: null,
      keys: ['comments', 'reactions', 'reposts'],
    };
  },

  computed: {
    platform() {
      return this.value.platform;
    },
    totalValue() {
      return reduce(this.values, (prev, data) => (isNaN(data) || !data ? 0 : data) + prev, 0);
    },
    postsCount() {
      return get(this.value, 'reports.posts', 0);
    },
    values() {
      return [
        get(this.value, 'reports.comments', 0),
        get(this.value, 'reports.reactions', 0),
        get(this.value, 'reports.shares', 0),
      ];
    },
  },

  async mounted() {
    await this.$nextTick();
    this.reloadChartJs();
  },

  beforeDestroy() {
    this.destroyChartJs();
  },

  watch: {
    values: {
      handler(curr, prev) {
        if (this.chartInstance && isEqual(curr, prev)) return;
        this.reloadChartJs();
      },
    },
  },

  methods: {
    destroyChartJs() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },

    reloadChartJs() {
      const { chart: chartEl } = this.$refs;
      if (!chartEl) return;
      this.destroyChartJs();

      const deliveredData = this.totalValue
        ? {
            labels: this.keys,
            datasets: [
              {
                data: this.values,
                backgroundColor: ['#33d69f', '#3f51b5', '#93a2f5'],
                hoverBackgroundColor: ['#33d69f', '#3f51b5', '#93a2f5'],
                borderWidth: fill(new Array(this.keys.length), 0),
              },
            ],
          }
        : {
            labels: ['No Data'],
            datasets: [
              {
                data: [100],
                backgroundColor: ['#c4c4c4'],
                hoverBackgroundColor: ['#c4c4c4'],
                borderWidth: [0],
              },
            ],
          };

      const deliveredOpt = {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        animation: {
          animationRotate: true,
          duration: 2000,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: !!this.totalValue,
        },
      };

      this.chartInstance = new ChartJs(chartEl, {
        type: 'doughnut',
        data: deliveredData,
        options: deliveredOpt,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.single-platform-engagement-entry {
  display: inline-flex;
  flex-wrap: wrap;
  width: 480px;
  padding: 20px 25px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  align-content: flex-start;

  ::v-deep {
    > * {
      display: flex;
      width: 100%;
      align-items: center;
    }

    > .header {
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      > .icon-wrapper {
        display: inline-flex;
        width: 40px;
        height: 40px;
        color: #ffffff;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        > .icon {
          width: 14px;
          height: 14px;
          margin: 4px 0 0 0;
        }

        &.facebook {
          background-color: #3b5998;

          > .icon {
            width: 13px;
            height: 13px;
            margin: 3px 0 0 0;
          }
        }

        &.twitter {
          background-color: #3d82b6;
        }

        &.reddit {
          background-color: #bd3737;
        }

        &.linkedin {
          background-color: #46e1c4;
        }

        &.wordpress {
          background-color: #ae7c31;
        }

        &.medium {
          background-color: #9e3192;
        }

        > .icon {
          display: inline-flex;
          transform: scale(1.9);
        }
      }

      > .name {
        display: inline-flex;
        color: #000000;
        text-transform: capitalize;
        font-size: 14px;
        margin: 0 0 0 15px;
      }
    }

    > .data {
      margin: 10px 0 0 0;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;

      > .chart-wrapper {
        display: flex;
        width: 100%;
        height: 270px;
        position: relative;
        justify-content: center;
        align-items: center;

        > .value {
          background-color: initial;
          display: inline-flex;
          flex-wrap: nowrap;
          font-size: 14px;
          position: absolute;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.2);
          font-size: 14px;
          font-weight: 400;
          z-index: 1;
        }
      }

      > .caption {
        display: flex;
        width: 100%;
        color: #404040;
        font-size: 13px;
        text-transform: capitalize;
        justify-content: center;
        text-align: center;
      }

      > .value-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        margin: 30px 0 0 0;

        > * {
          display: inline-flex;
          width: 50%;
          margin: 0;
          padding: 20px;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          align-content: flex-start;

          > * {
            display: inline-flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }

          > .title {
            text-transform: capitalize;

            &::before {
              display: inline-flex;
              content: '';
              width: 16px;
              height: 16px;
              background-color: #c4c4c4;
              border-radius: 50%;
              margin: 0 20px 0 0;
            }
          }

          > .value {
            margin: 20px 0 0 0;
          }

          &:first-child {
            border-right: 1px solid #c4c4c4;
          }

          &.comments {
            > .title {
              &::before {
                background-color: #33d69f;
              }
            }
          }

          &.reactions {
            > .title {
              &::before {
                background-color: #93a2f5;
              }
            }
          }

          &.reposts {
            > .title {
              &::before {
                background-color: #3f51b5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
